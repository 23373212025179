import { useReactive } from 'ahooks'

function Dropdown ({ menus, store, data, route, children }) {
  const [ hover, dispatch ] = React.useState(false)

  return <div className='w-28 medium:w-32 widescreen:w-36 h-full relative' onMouseOver={() => dispatch(true)} onMouseLeave={() => dispatch(false)}>
    <div onClick={() => store.navigate.absolute(data.key)} className='h-full w-full flex items-center justify-center transition duration-300 ease-in-out hover:text-white hover:bg-blue-800'>
      {children}
    </div>
    <ul
      style={{ maxHeight: hover ? menus.length * 50 : 0, display: R.isEmpty(menus) ? 'none' : 'block' }}
      className={`absolute bg-white text-sm text-center text-gray-800 z-10 divide-y divide-gray-100 transform -translate-y-px transition-all duration-500 ease-in-out overflow-hidden shadow ${children == '方案中心' ? 'w-60 -translate-x-12' : 'w-full'}`}
    >
      {menus.map(elm => {
        return <li
          key={elm.key}
          onClick={() => store.navigate.go(elm.key)}
          className={`py-3 px-4 font-normal text-xs medium:text-sm hover:text-blue-800 ${new RegExp(`^${elm.key}`).test(route.url) ? 'text-blue-800 font-bold' : 'text-gray-800'}`.trim()}
        >
          {elm.title}
        </li>
      })}
    </ul>
  </div>
}

export default function View ({ res, store, route }){
  const data = useReactive({
    name: null,
    expand: false,
  })

  if(store.responsive.isDesktop) {
    return <header className='bg-white shadow flex relative'>
      <div className='flex-none flex items-center justify-between cursor-pointer p-2 medium:p-4' onClick={() => store.navigate.go('/home')}>
        <img className='h-14 medium:h-16 object-cover' src={store.config.loadImg('/images/logo.png')} />
      </div>

      <ul className='flex-none flex justify-center items-center px-2 medium:px-4'>
        {store.config.menus.map(elm => {
          const getMenus = elm.key == '/home'
            ? R.propOr([], 'children')
            : R.propOr(res.product.map(e => ({ title: e.title, key: `/product/list/${e.id}` })), 'children')

          return <li
            key={elm.key}
            className={`h-full cursor-pointer text-sm medium:text-lg ${new RegExp(`^${elm.key}`).test(route.url) ? 'text-blue-800 font-bold' : 'text-gray-800'}`}
          >
            <Dropdown store={store} route={route} data={elm} menus={getMenus(elm)}>
              {elm.title}
            </Dropdown>
          </li>
        })}
      </ul>

      <div className='flex-auto flex justify-end items-center space-x-4 px-2 medium:px-4'>
        <div className='flex items-center justify-center space-x-2 text-blue-800'>
          <div className='text-sm medium:text-xl fa fa-volume-control-phone' />
          <div className='text-sm medium:text-xl italic font-bold'>400-6997-916</div>
        </div>

        <input
          onChange={e => Object.assign(data, { name: e.target.value })}
          onKeyPress={R.when(e => e.code == 'Enter' && data.name, () => store.navigate.go(`/product/search/${encodeURIComponent(data.name)}`))}
          type='text'
          placeholder='根据产品名称查询'
          className='p-2 w-60 medium:w-64 rounded-sm outline-none transition duration-300 border border-gray-600 focus:border-blue-800 focus:ring-2 focus:ring-blue-800 focus:ring-opacity-60'
        />
      </div>
    </header>
  }

  return <header className='p-2 bg-white fixed shadow inset-x-0 top-0' style={{ zIndex: 999 }}>
    <div className='flex items-center justify-between'>
      <img className='h-10 object-cover' src={store.config.loadImg('/images/logo.png')} onClick={() => store.navigate.go('/home')} />
      <div className='mx-4 fa fa-bars text-xl transition-all text-blue-800' onClick={() => Object.assign(data, { expand: R.not(data.expand) })}></div>
    </div>

    <div className='text-base text-gray-600 overflow-hidden transition-all duration-500 ease-in-out' style={{ maxHeight: data.expand ? 400 : 0 }}>
      <ul className='p-2 px-4 border-t border-gray-200'>
        {store.config.menus.map(elm => {
          return <li
            key={elm.key}
            onClick={() => store.navigate.absolute(elm.key)}
            className={`py-2 ${new RegExp(`^${elm.key}`, 'g').test(route.url) ? 'text-blue-800 font-bold' : 'text-gray-800'}`}
          >
            {elm.title}
          </li>
        })}
      </ul>
    </div>
  </header>
}
