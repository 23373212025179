import { Modal } from 'antd-mobile'

import { useRef } from 'react'
import { useReactive, useClickAway } from 'ahooks'

function Popover({ trigger, children, content = null }) {
  const ref = useRef()
  const data = useReactive({ focus: false, hover: false })

  useClickAway(() => {

    data.focus = false

  }, ref)

  if(content == null) {
    return children
  }

  return <div className='relative'>
    <div
      style={{ opacity: trigger == 'hover' ? (data.hover ? 1 : 0) : (data.focus ? 1 : 0) }}
      className='px-3 py-1.5 rounded-sm text-center bg-white shadow absolute -top-2 left-1/2 z-50 transform -translate-y-full -translate-x-1/2 transition ease-in-out duration-300'
    >
      <div className='text-gray-800 text-sm'>{content}</div>
      <div className='w-0 h-0 absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-3/4' style={{ border: '6px solid transparent', borderTop: '12px solid white' }} />
    </div>

    <div
      ref={ref}
      onClick={() => data.focus = true}
      onMouseOver={() => data.hover = true}
      onMouseLeave={() => data.hover = false}
    >
      {children}
    </div>
  </div>
}

export default function View ({ res, store }) {
  if(store.responsive.isDesktop) {
    return <footer className='bg-gray-900 text-white flex justify-center p-16'>
      <article className='flex flex-col items-center space-y-4 w-52'>
        <h3 className='text-lg font-bold'>产品中心</h3>

        <ul className='text-base flex flex-col items-center space-y-4'>
          {res.product.map(elm => {
            return <li
              key={elm.id}
              onClick={() => store.navigate.go(`/product/list/${elm.id}`)}
              className='cursor-pointer hover:font-bold hover:underline'
            >
              {elm.title}
            </li>
          })}
        </ul>
      </article>

      <article className='flex flex-col items-center space-y-4 w-56'>
        <h3 className='text-lg font-bold'>方案中心</h3>

        <ul className='text-base text-center flex flex-col items-center space-y-4'>
          {store.config.getConstantValues('Program').map(elm => {
            return <li className='cursor-pointer hover:font-bold hover:underline' onClick={() => store.navigate.go(`/program/${elm.value}`)}>
              {elm.description}
            </li>
          })}
        </ul>
      </article>

      <article className='flex flex-col items-center space-y-4 w-52'>
        <h3 className='text-lg font-bold'>资讯中心</h3>

        <ul className='text-base text-center flex flex-col items-center space-y-4'>
          <li className='cursor-pointer hover:font-bold hover:underline' onClick={() => store.navigate.go('/consult/1')}>行业资讯</li>
          <li className='cursor-pointer hover:font-bold hover:underline' onClick={() => store.navigate.go('/consult/2')}>公司资讯</li>
        </ul>
      </article>

      <article className='flex flex-col items-center space-y-4 w-52'>
        <h3 className='text-lg font-bold'>服务中心</h3>

        <ul className='text-base text-center flex flex-col items-center space-y-4'>
          <li className='cursor-pointer hover:font-bold hover:underline' onClick={() => store.navigate.go('/server/sla')}>售后服务</li>
          <li className='cursor-pointer hover:font-bold hover:underline' onClick={() => store.navigate.go('/server/warranty')}>保修服务</li>
          <li className='cursor-pointer hover:font-bold hover:underline' onClick={() => store.navigate.go('/server/download')}>资料下载</li>
        </ul>
      </article>

      <article className='flex flex-col items-center space-y-4 w-52'>
        <h3 className='text-lg font-bold'>关于昊源</h3>

        <ul className='text-base text-center flex flex-col items-center space-y-4'>
          <li className='cursor-pointer hover:font-bold hover:underline' onClick={() => store.navigate.go('/about/profile')}>公司简介</li>
          <li className='cursor-pointer hover:font-bold hover:underline' onClick={() => store.navigate.go('/about/partnership')}>合作伙伴</li>
          <li className='cursor-pointer hover:font-bold hover:underline' onClick={() => store.navigate.go('/about/contact')}>联系我们</li>
        </ul>
      </article>

      <article className='flex flex-col items-start ml-8 space-y-8'>
        <div className='flex items-center space-x-16'>
          <div className='space-y-6'>
            <img className='w-64 object-cover' src={store.config.loadImg('/images/logo-clear.png')} />

            <ul className='flex items-center justify-between space-x-6 text-3xl'>
              <li>
                <Popover trigger='hover' content='427959771'>
                  <div className='fa fa-qq cursor-pointer'/>
                </Popover>
              </li>

              <li>
                <Popover trigger='hover' content='marketing@hynx.com.cn'>
                <div className='fa fa-envelope cursor-pointer'/>
                </Popover>
              </li>

              <li>
                <Popover trigger='hover' content='haoyuannuoxin'>
                  <div className='fa fa-weixin cursor-pointer'/>
                </Popover>
              </li>

              <li>
                <Popover trigger='hover'>
                  <div className='fa fa-weibo'/>
                </Popover>
              </li>
            </ul>
          </div>

          <div>
            <img className='w-32 object-cover' src={store.config.loadImg('/images/logo-qrcode.jpg')} />
          </div>
        </div>

        <figure className='space-y-2'>
          <figcaption className='text-lg font-bold'>电话：400-6997-916</figcaption>
          <figcaption className='text-base font-normal'>深圳总部：{res.address?.desc.split('-')[1]}</figcaption>
          <figcaption className='text-base font-normal'>广州公司：广东省广州市天河区大观中路95号1栋</figcaption>
          <figcaption className='text-base font-normal'>武汉公司：湖北省武汉市东湖新技术开发区</figcaption>
          <figcaption className='text-base font-normal'>成都公司：四川省成都市高新区吉泰路666号福年广场T2</figcaption>
        </figure>
      </article>
    </footer>
  }

  return <footer className='bg-gray-900 text-white'>
    <div className='p-4 text-base text-center font-bold'>深圳市昊源诺信科技有限公司</div>

    <div className='p-2'>
      <ul className='flex items-center justify-center space-x-6 text-2xl'>
        <li>
          <Popover trigger='click' content='427959771'>
            <div className='fa fa-qq cursor-pointer'/>
          </Popover>
        </li>

        <li>
          <Popover trigger='click' content='marketing@hynx.com.cn'>
            <div className='fa fa-envelope cursor-pointer'/>
          </Popover>
        </li>

        <li>
          <Popover trigger='click'>
            <div className='fa fa-weixin cursor-pointer' onClick={() => Modal.show({ content: <img className='w-64' src={store.config.loadImg('/images/logo-qrcode.jpg')} />, closeOnMaskClick: true })}/>
          </Popover>
        </li>

        <li>
          <Popover trigger='click'>
            <div className='fa fa-weibo'/>
          </Popover>
        </li>
      </ul>
    </div>

    <div className='p-2 px-8 text-lg font-bold text-center'>电话：400-6997-916</div>
    <div className='p-2 px-8 text-sm text-center'>深圳总部：{res.address?.desc.split('-')[1]}</div>
    <div className='p-2 px-8 text-sm text-center'>广州公司：广东省广州市天河区大观中路95号1栋</div>
    <div className='p-2 px-8 text-sm text-center'>武汉公司：湖北省武汉市东湖新技术开发区</div>
    <div className='p-2 px-8 pb-8 text-sm text-center'>成都公司：四川省成都市高新区吉泰路666号福年广场T2</div>
  </footer>
}
